import { css } from "@emotion/react";
import theme from "src/assets/styles/theme.js";

export default css`
  //RESET
  /* http://meyerweb.com/eric/tools/css/reset/ 
    v2.0 | 20110126
    License: none (public domain)
    */

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  //--RESET

  ::-moz-selection {
    background: #000;
    color: #ffffff;
  }
  ::selection {
    background: #000;
    color: #ffffff;
  }

  *:focus {
    outline: none;
  }

  body,
  html {
    padding: 0;
    margin: 0;

    font-family: "GT-America", Helvetica, Arial, sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;

    font-size: 62.5%;
    font-style: normal;
    cursor: default;
    color: #fff;
  }

  a, .transition {
    transition: all 300ms ease-in;
    color: inherit;
    &:hover {
      text-decoration: none;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: "ACaslonPro", serif;
    font-style: normal;
    font-weight: normal;
  }

  .hero1.fp-section.fp-table {
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    @media screen and (max-width: 720px) {
      width: 80vh;
    }
    @media screen and (min-width: 401px) {
      width: 100vh;
    }
    transition: width 0.8s ease;

    > .fp-tableCell {
      > .headingWrapper {
        opacity: 1 !important; // We need "!important" to override necessary inline style

        @media screen and (max-width: 720px) {
          transform: translateY(42vh);
        }
        @media screen and (max-width: 1152px) and (min-width: 721px) {
          transform: translateY(50vh);
        }
        @media screen and (min-width: 1153px) {
          transform: translateY(50vh);
        }
        transition: transform 0.8s ease, opacity 0.8s ease-in-out 0.2s;
      }

      > .imgOverlay {
        opacity: 0.2;
        transition: opacity 0.8s ease;
      }
      
      > .background-image,
      > div > .background-video,
      .imgOverlay {
        @media screen and (max-width: 720px) {
          transform: translateY(88.5vh) scale(0.4);
        }
        @media screen and (max-width: 1152px) and (min-width: 721px) {
          transform: translateY(95vh) scaleX(0.5) scaleY(0.4);
        }
        @media screen and (min-width: 1153px) {
          transform: translateY(100vh) scale(0.7);
        }
        transition: transform 0.8s ease, opacity 0.8s;
      }
    }
    &.active {
      width: 100%;

      > .fp-tableCell {
        > .imgOverlay {
          opacity: 0;
        }
        > .background-image,
        > div > .background-video,
        .imgOverlay {
          transform: translateY(0) scale(1);
        }
        > .headingWrapper {
          z-index: 1000 !important;
          transition: opacity 1s ease-in-out;
        }
      }
    }
  }

  .hero2.fp-section.fp-table {
    opacity: 0;
    transition: opacity 0.8s ease-in-out 0.2s;

    &.active {
      opacity: 1;
    }
  }
  #fp-nav {
    @media only screen and (max-width: 1151px) {
      display: none;
    }
    color: currentColor;
  }

  #fp-nav.fp-right {
    right: 25px !important;
  }
  #fp-nav.fp-right ul li:last-of-type {
    display: none;
  }

  #fp-nav > ul > li > a > span {
    background: currentColor !important;
    border: 1px solid currentColor !important;
    box-sizing: border-box;
  }
  #fp-nav > ul > li > a:not(.active) {
    opacity: 0.5;
  }
  #fp-nav > ul > li > a:not(.active):hover {
    opacity: 1;
  }
  #fp-nav > ul > li > a.active > span {
    background: transparent !important;
    border: 1px solid currentColor !important;
  }

  #fp-nav ul li a, .fp-slidesNav ul li a { 
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: 0!important;
      width: 3px;
      height: 3px;
      transform: translate(-50%, -50%);
    }
  }

  #fp-nav ul li a.active span,
  .fp-slidesNav ul li a.active span {
    width: 7px!important;
    height: 7px!important;
    margin: 0!important;
  }
  #fp-nav ul li:hover a span, #fp-nav ul li:hover a.active span,
  .fp-slidesNav ul li:hover a span, .fp-slidesNav ul li:hover a.active span {
    width: 7px!important;
    height: 7px!important;
    margin: 0!important;
  }

  .page-scroll {
    h3:first-of-type {

      @media (max-width: 1151px) {
        max-width: 75%;
      }

      @media (max-width: 940px) {
        max-width: 60%;
      }

      @media (max-width: 720px) {
        max-width: none;
      }
    }

    #fp-nav {
      @media only screen and (max-width: 1151px) {
        display: none;
      }

      ul {
        li {
          color: currentColor;
          margin: 0;
          padding: 16px 0 16px 0;
          position: relative;
          &:after {
            position: absolute;
            content: "";
            display: block;
            width: 1px;
            background-color: currentColor;
            top: 24.5px;
            left: 50%;
            transform: translateX(-50%);
            height: 92%;
            opacity: 0.3;
          }
        }
        !li a.active {
          &:after {
            opacity: 0.3 !important;
          }
        }
        li:first-of-type {
          display: none;
        }
        li:nth-last-child(2) {
          &:after {
            display: none;
          }
        }
      }

      color: currentColor;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        & + .fp-tooltip {
          opacity: 0.3;
        }
        &.active {
          + .fp-tooltip {
            color: currentColor;
          }
          span:last-of-type {
            margin: 0;
            height: 7px;
            width: 7px;
            border-color: currentColor;
          }
        }
        &:not(.active) {
          span:last-of-type {
            opacity: 0.3 !important;
          }
        }
        span:first-of-type {
          display: none;
        }
        span:last-of-type {
          display: block;
          border-radius: 50%;
          position: absolute;
          z-index: 1;
          height: 5px;
          width: 5px;
          border: 0;
          background: currentColor;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          margin: 0;
          transition: all 0.1s ease-in-out;
        }
      }
    }

    #fp-nav.fp-right {
      right: 25px !important;
    }

    #fp-nav ul li .fp-tooltip {
      top: 17px;
    }

    #fp-nav > ul > li > a > span {
      background: currentColor;
      border: 1px solid currentColor;
      box-sizing: border-box;
      opacity: 0.3;
    }
    #fp-nav > ul > li > a.active {
      & + .fp-tooltip.fp-right {
        opacity: 1;
      }
      & > span {
        background: transparent !important;
        border: 1px solid currentColor !important;
        opacity: 1;
      }
    }

    #fp-nav ul li .fp-tooltip.fp-right {
      transition: all 300ms ease;

      color: currentColor;
      width: auto;
      text-transform: uppercase;
      opacity: 0.3;
      font-size: 1.2rem;
    }
  }

  body:not([class*=" fp-viewing-"]),
  body:not([class^="fp-viewing-"]),
  body.page-scroll.fp-viewing-0 {
    &.page-scroll #fp-nav > ul > li {
      &:after,
      & > a + .fp-tooltip.fp-right,
      & > a span:last-of-type {
        opacity: 1 !important;
      }
    }
  }

  body[class*=" fp-viewing-"],
  body[class^="fp-viewing-"] {
    &.page-scroll #fp-nav > ul > li {
      &:after,
      & > a + .fp-tooltip.fp-right,
      & > a span:last-of-type {
        opacity: 0.3 !important;
      }
      a.active + .fp-tooltip.fp-right,
      & > a.active span:last-of-type {
        opacity: 1 !important;
      }
    }
    &.page-scroll #fp-nav > ul > li {
      transition: all 300ms ease;
      &.no-show {
        opacity: 0!important;
      }
    }
  }

  #___gatsby {
    font-size: 1.3rem;
  }
`;
