import React from 'react';
import styled from 'theme/libs/styled';
import { ThemeProvider } from '@emotion/react';
import fonts from 'assets/fonts/fonts';
import theme from 'assets/styles/theme';
import { Global } from '@emotion/react';
import GlobalStyle from 'assets/styles/GlobalStyle.js';

const AppStyles = styled('div')`
  ${fonts}
  * {
    box-sizing: border-box;
  }
`;

const Layout = ({ children, t, i18n }) => {

 return (
   <ThemeProvider suppressHydrationWarning theme={theme}>
     <Global styles={GlobalStyle} />
     <AppStyles>{children}</AppStyles>
   </ThemeProvider>
 );
};

export default Layout;