const theme = {

  // FONT SIZE
  //
  fontSizes: {
    headline1: {
      s: 'calc(3.7rem + (8.3 - 3.7) * ((100vw - 32rem) / (72 - 32)));',
      m: 'calc(6.9rem + (11 - 6.9) * ((100vw - 72rem) / (115.2 - 72)));',
      l: 'calc(6.4rem + (14 - 6.4) * ((100vw - 115.2rem) / (256 - 115.2)));',
    },
    headline2: {
      s: 'calc(3.09rem + (6.95 - 3.09) * ((100vw - 32rem) / (72 - 32)));',
      m: 'calc(5.53rem + (8.83 - 5.53) * ((100vw - 72rem) / (115.2 - 72)));',
      l: 'calc(5.1rem + (11.4 - 5.1) * ((100vw - 115.2rem) / (256 - 115.2)));',
    },
    headline3: {
      l:
        'calc(4.48rem + (9.96 - 4.48) * ((100vw - 115.6rem) / (256 - 115.6)));',
    },
    headline4: {
      m: 'calc(4.14rem + (6.62 - 4.14) * ((100vw - 72rem) / (115.2 - 72)));',
      l: 'calc(3.84rem + (8.53 - 3.84) * ((100vw - 115.2rem) / (256 - 115.2)));',
    },
    headline5: {
      s: '2.4rem',
      m: 'calc(3.35rem + (5.52 - 3.35) * ((100vw - 72rem) / (115.6 - 72)));',
      l: 'calc(3.2rem + (7.11 - 3.2) * ((100vw - 115.2rem) / (256 - 115.2)));',
    },
    ingress1: {
      s: 'calc(2.47rem + (5.56 - 2.47) * ((100vw - 32rem) / (72 - 32)));',
      m: 'calc(2.76rem + (4.42 - 2.76) * ((100vw - 72rem) / (115 - 72)));',
      l: 'calc(2.56rem + (5.69 - 2.56) * ((100vw - 115.6rem) / (256 - 115)));',
    },
    ingress2: {
      s: 'calc(1.85rem + (4.17 - 1.85) * ((100vw - 32rem) / (72 - 32)));',
      m: 'calc(2.07rem + (3.31 - 2.07) * ((100vw - 72rem) / (115 - 72)));',
      l: 'calc(1.92rem + (4.27 - 1.92) * ((100vw - 115.2rem) / (256 - 115)));',
    },
    tag: {
      s: '1.2rem;',
      m: '1.2rem;',
      l: '1.2rem',
      xl: 'calc(1.2rem + (1.6 - 1.2) * ((100vw - 115.2rem) / (256 - 115)));',
    },
    paragraph: {
      s: '1.6rem',
      m: 'calc(1.4rem + (2.0 - 1.4) * ((100vw - 72rem) / (115 - 72)));',
      l: 'calc(1.4rem + (2.84 - 1.24) * ((100vw - 99.2rem) / (256 - 0)));',
    },
  },

  // LINE HEIGHT
  //
  lineHeight: {
    paragraph: {
      s: '2.1rem;',
      m: 'calc(1.4rem + (4.17 - 1.4) * ((100vw - 72rem) / (115 - 72)));',
      l: 'calc(2.1rem + (4.27 - 2.1) * ((100vw - 99.2rem) / (256 - 0)));',
    }
  },

  // Padding
  //
  spacing: {
    eight: {
      s: 'calc(0.8rem + (1.39 - 0.8) * ((100vw - 32rem) / (72 - 32)));',
      l: 'calc(0.8rem + (1.42 - 0.8) * ((100vw - 99.2rem) / (256 - 0)));',
    },
    sixteen: {
      s: 'calc(1.24rem + (2.78 - 1.24) * ((100vw - 32rem) / (72 - 32)));',
      l: 'calc(1.28rem + (2.84 - 1.28) * ((100vw - 99.2rem) / (256 - 0)));',
    },
    twentyfour: {
      xs: 'calc(1.16rem + (4.17 - 1.86) * ((100vw - 32rem) / (72 - 32)));',
      s: 'calc(1.86rem + (4.17 - 1.86) * ((100vw - 32rem) / (72 - 32)));',
      l: 'calc(1.92rem + (4.27 - 1.92) * ((100vw - 99.2rem) / (256 - 0)));',
    },
    thirtytwo: {
      s: 'calc(2.47rem + (5.56 - 2.47) * ((100vw - 32rem) / (72 - 32)));',
      l: 'calc(2.56rem + (5.69 - 2.56) * ((100vw - 99.2rem) / (256 - 0)));',
    },
    forty: {
      s: 'calc(3.1rem + (6.95 - 3.1) * ((100vw - 32rem) / (72 - 32)));',
      l: 'calc(3.2rem + (7.11 - 3.2) * ((100vw - 99.2rem) / (256 - 0)));',
    },
    sixtyfour: {
      s: 'calc(4.33rem + (9.73 - 4.33) * ((100vw - 32rem) / (72 - 32)));', // 56
      l: 'calc(5.12rem + (11.38 - 5.12) * ((100vw - 99.2rem) / (256 - 0)));', // 64
    },
    eightyeight: {
      s: 'calc(6.8rem + (9.6 - 6.8) * ((100vw - 32rem) / (72 - 32)));', // 72
      l: 'calc(6.8rem + (15.283 - 6.8) * ((100vw - 99.2rem) / (256 - 0)));', // 88
    },
    big: {
      s: 'calc(6.8rem + (15.83 - 6.8) * ((100vw - 32rem) / (72 - 32)));', // 72
      l: 'calc(10.4rem + (23.11 - 10.4) * ((100vw - 99.2rem) / (256 - 0)));', // 88
    }

    // xxl: "calc(2.56rem + (5.69 - 2.56) * ((100vw - 99.2rem) / (256 - 0)));", // 90

  },

  color: {
    primary: '#000000',
    white: '#ffffff',
    black: '#000000',
    lightGray: '#eaeaea',
    silver: '#BDBDBD',
    mediumGray: '#828282',
    darkGray: '#414448',
    red: '#B40A1C',
    brown: '#5E544D',
    kvanum: '#54565A',
    skyLightBlue: '#A8AEB5',
  },
};

export default theme;
