import { css } from '@emotion/react';

import ACaslonProEot from './acaslon/ACaslonPro-Regular.eot';
import ACaslonProTtf from './acaslon/ACaslonPro-Regular.ttf';
import ACaslonProWoff from './acaslon/ACaslonPro-Regular.woff';
import ACaslonProWoff2 from './acaslon/ACaslonPro-Regular.woff2';

import GTAmericaEot from './gt-america/GT-America-Standard-Regular.eot';
import GTAmericaTtf from './gt-america/GT-America-Standard-Regular.ttf';
import GTAmericaWoff from './gt-america/GT-America-Standard-Regular.woff';
import GTAmericaWoff2 from './gt-america/GT-America-Standard-Regular.woff2';

import GTAmericaLightEot from './gt-america/GT-America-Standard-Light.eot';
import GTAmericaLightTtf from './gt-america/GT-America-Standard-Light.ttf';
import GTAmericaLightWoff from './gt-america/GT-America-Standard-Light.woff';
import GTAmericaLightWoff2 from './gt-america/GT-America-Standard-Light.woff2';

export default css`
  @font-face {
    font-family: "ACaslonPro";
    font-weight: 400;
    font-style: normal;
    font-display: block;
    src: url(${ACaslonProWoff2}) format("woff2"),
      url(${ACaslonProWoff}) format("woff"),
      url(${ACaslonProTtf}) format("ttf");
      url(${ACaslonProEot}) format("eot"),
  }

  @font-face {
    font-family: "GT-America";
    font-weight: 400;
    font-style: normal;
    font-display: block;
    src: url(${GTAmericaWoff2}) format("woff2"),
      url(${GTAmericaWoff}) format("woff"),
      url(${GTAmericaTtf}) format("ttf");
      url(${GTAmericaEot}) format("eot"),
  }

  @font-face {
    font-family: "GT-America";
    font-weight: 300;
    font-style: normal;
    font-display: block;
    src: url(${GTAmericaLightWoff2}) format("woff2"),
      url(${GTAmericaLightWoff}) format("woff"),
      url(${GTAmericaLightTtf}) format("ttf");
      url(${GTAmericaLightEot}) format("eot"),
  }
`;
