import React from 'react';
import Cookies from 'js-cookie'; // https://www.npmjs.com/package/js-cookie#json
import { COOKIE_FAVORITES } from 'components/global/constants';

export const FavoritesContext = React.createContext();

class Provider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            favorites: [],
        };
        this.isFavorite = this.isFavorite.bind(this);
        this.toggleFavorite = this.toggleFavorite.bind(this);
        this.updateCookies = this.updateCookies.bind(this);
    }

    componentDidMount() {
        const cookie = Cookies.get(COOKIE_FAVORITES);
        if (cookie) {
            try {
                const favorites = JSON.parse(cookie);
                this.setState({
                    favorites
                });
            } catch (e) {
                console.log('Parsing of favorites failed', cookie);
            }
        }
    }

    isFavorite(data) {
        const { favorites } = this.state;
        const index = favorites.map(f => {
            return f.url;
        }).indexOf(data?.url);
        return index !== -1;
    }

    toggleFavorite(data) {
        let { favorites } = this.state;

        const index = favorites.map(fav => {
            return fav.url;
        }).indexOf(data?.url);

        if (index !== -1) {
            favorites.splice(index, 1);
        } else {
            data.url = window.location.href;
            favorites = [data, ...this.state.favorites];
        }

        this.setState({
            favorites
        }, this.updateCookies(favorites));
    }

    updateCookies(data) {
        const newCookie = JSON.stringify(data);
        Cookies.set(COOKIE_FAVORITES, newCookie, { expires: 100 });
    }

    render() {
        let { favorites } = this.state;
        favorites = favorites.sort((a, b) => {
            return (a.text.localeCompare(b.text));
        });
        return (
            <FavoritesContext.Provider
                value={{
                    favorites,
                    isFavorite: this.isFavorite,
                    toggleFavorite: this.toggleFavorite
                }}
            >
                {this.props.children}
            </FavoritesContext.Provider>
        );
    }
}

export default Provider;